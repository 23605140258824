import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { List, Input, Textarea, Error, DateInput, TimeInput } from '../elements/';
import { useStores } from '../../hooks';
import styles from '../../assets/scss/orderForm.module.scss';

const OrderForm = ({ order }) => {
  const { formStore } = useStores();

  useEffect(() => {
    formStore.getPickupDates()
  }, [formStore]);


  return useObserver(() => {
    const { amountOfBoxes } = formStore;
    // const amounts = formStore.getMaxAmount(order.date);
    const amounts = [
      {
        text: "1kg",
        value: 1,
      },
      {
        text: "2kg",
        value: 2,
      },
      {
        text: "3kg",
        value: 3,
      },
      {
        text: "4kg",
        value: 4,
      },
      {
        text: "5kg",
        value: 5,
      },
      {
        text: "6kg",
        value: 6,
      },
      {
        text: "7kg",
        value: 7,
      },
      {
        text: "8kg",
        value: 8,
      },
      {
        text: "9kg",
        value: 9,
      },
      {
        text: "10kg",
        value: 10,
      },
      {
        text: "11kg",
        value: 11,
      },
      {
        text: "12kg",
        value: 12,
      },
      {
        text: "13kg",
        value: 13,
      },
      {
        text: "14kg",
        value: 14,
      },
      {
        text: "15kg",
        value: 15,
      },
      {
        text: "16kg",
        value: 16,
      },
      {
        text: "17kg",
        value: 17,
      },
      {
        text: "18kg",
        value: 18,
      },
      {
        text: "19kg",
        value: 19,
      },
      {
        text: "20kg",
        value: 20,
      },
    ]

    return (
      <>
        <section className={styles.inputs}>
          <Error activeErrors={order.activeErrors}/>
          <div className={styles.grid} style={{marginBottom: '20px'}}>
            <div>
              <label htmlFor="email">Afhaaldatum en tijdstip<strong>*</strong></label>
              <div className={styles.dateGrid}>
                <DateInput entity={order} name="date"/>
                <TimeInput entity={order} name="order_time"/>
              </div>
            </div>
            <div>
              <label htmlFor="name">Aantal kg<strong>*</strong></label>
              <List entity={order} list={amounts} name="amount" value="value" text="text"/>
            </div>

            <div>
              <div className={styles.fruitBox}>
                <label htmlFor="name">Aantal fruitboxen</label>
              </div>
              <List entity={order} list={amountOfBoxes} name="boxes" value="value" text="text"/>
            </div>
          </div>     

          <div>
            <label htmlFor="email">Contactgegevens <strong>*</strong></label>
            <div className={`${styles.grid} ${styles.combinedInputs}`}>
              <Input entity={order} name="name" placeholder="Voornaam + naam"/>
              <Input entity={order} type="tel" name="telno" placeholder="Telefoonnummer"/>
              <Input entity={order} name="address" placeholder="Adres + huisnummer"/>
              <Input entity={order} name="postal" placeholder="Postcode + gemeente"/>
              <Input entity={order} name="email" placeholder="E-mail"/>
            </div>
          </div>

          <div className={styles.extraInfo}>
            <label htmlFor="message">Extra mededeling </label>
            <Textarea entity={order} name="message" id="message" placeholder="Vul hier uw extra opmerkingen in."/>
          </div>
        </section>
      </>
    )
  })
}

export default OrderForm;
