import React from 'react';
import { useObserver } from "mobx-react-lite"
import {useRedirect} from '../../hooks';
import styles from '../../assets/scss/home.module.scss'

const Home = () => {
  const { redirect } = useRedirect();

  const Item = ({ text, link, icon }) => {
    return (
      <div className={styles.item} onClick={() => redirect(link)}>
        <i className={icon} />
        <span>{text}</span>
      </div>
    )
  };

  return useObserver(() => {
    return (
      <main className="cms">
        <h1 className="title">Dashboard</h1>
        <div className={styles.items} >
          <Item text="Instellingen" icon="fas fa-cog" link="/settings"/>
          <Item text="Stockbeheer" icon="fas fa-balance-scale-right" link="/stock"/>
          <Item text="Overzicht van een dag" icon="fas fa-chart-line" link="/overview/day"/>
          <Item text="Alle bestellingen" icon="fas fa-list" link="/orders"/>
        </div>
      </main>
    )
  })
}


export default Home;
