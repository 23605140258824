import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';

import { useStore } from '../../hooks';
import { Messages } from '../I18n';
import { Doughnut, DateInput } from '../elements';
import styles from '../../assets/scss/cmsStock.module.scss';

const Day = ({ day }) => {
  const [value, setValue] = useState('');
  const stockStore = useStore('stockStore');

  const increaseStock = () => {
    if (value === '') {
      Messages.error('Vul een geldig aantal in');
      return;
    }

    day.amount = parseInt(day.amount, 10) + parseInt(value, 10);
    stockStore.setStockAmount(day);
    setValue('');
    Messages.success('De stock is succesvol opgeslaan');
  };

  const decreaseStock = () => {
    if (value === '') {
      Messages.error('Vul een geldig aantal in');
      return;
    }

    if ((parseInt(day.amount, 10) - parseInt(value, 10)) < 0) {
      Messages.error(`Vul een bedrag in kleiner dan ${day.amount}`)
      return;
    }

    day.amount = parseInt(day.amount, 10) - parseInt(value, 10);
    stockStore.setStockAmount(day);
    setValue('');
    Messages.success('De stock is succesvol opgeslaan');
  };

  const toggleLock = () => {
    day.active = !day.active;
    stockStore.setStockState(day);
  };

  const onChange = ev => {
    setValue(ev.target.value);
  };

  return useObserver(() => {
    return (
      <div className={styles.day}>
        <div className={styles.info}>
          <span className={`${styles.status} ${day.active ? styles.active : styles.deactive}`} />
          <span>{day.date}</span>
          <span style={{ marginLeft: '20px' }}>{day.day_name}</span>
          <i className={`${styles.icon} fas ${day.active ? 'fa-lock-open' : 'fa-lock'}`} onClick={toggleLock} />
        </div>

        {
          !day.active && <div className={styles.overlay} />
        }

        <div className={styles.content}>
          <div className={styles.chart}>
            {
              day.amount !== 0 && (
                <div className={styles.stock}>
                  <span>Totaal aantal: {day.amount}kg</span>
                  <span className={styles.used}>Reeds besteld: {day.ordered_amount}kg</span>
                  <span>Nog open om te bestellen: {day.amount - day.ordered_amount}kg</span>

                  <div className={styles.indicator}>
                    <div className={styles.used} style={{ width: `${(day.ordered_amount / day.amount) * 100}%` }} />
                  </div>
                </div>
              )
            }

            {
              day.amount === 0 && (
                <span>
                  Momenteel is er geen stock beschikbaar voor deze dag.
                  <br />
                  <br />

                  Voeg nieuwe stock toe door op de + te drukken.
                </span>
              )
            }


          </div>
        </div>

        <div className={styles.buttons}>
          <input type="number" min={0} value={value} onChange={onChange} />
          <div className={styles.actions}>
            <div className="btn" onClick={decreaseStock}>-</div>
            <div className="btn" onClick={increaseStock}>+</div>
          </div>
        </div>
      </div>
    )
  })

}


const CmsStock = () => {
  const stockStore = useStore('stockStore');

  useEffect(() => {
    stockStore.initalizeDays();

    return () => {
      stockStore.stopInterval();
    }
  }, []);

  return useObserver(() => {
    const { days, dateConfig } = stockStore;
    return (
      <main className="cms">
        <section>
          <div className={styles.title}>
            <h2 className="title">Stockbeheer</h2>
          </div>

          <div className={styles.dates}>
            <div>
              <label htmlFor="from">Van datum:</label>
              <DateInput entity={dateConfig} name="start" />
            </div>
            <div>
              <label htmlFor="from">Tot datum:</label>
              <DateInput entity={dateConfig} name="stop" />
            </div>
          </div>

          <div className={styles.days}>
            {days.map(day => (<Day day={day} />))}
          </div>
        </section>
      </main>
    )

  })
};

export default CmsStock;
