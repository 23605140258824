import React, { useState, useEffect } from 'react';
import { useObserver } from "mobx-react-lite";
import { Modal } from '../elements';
import styles from '../../assets/scss/modal.module.scss'
import { closeModal } from '../../utils';
import QrReader from 'react-qr-reader'
import { useStores } from '../../hooks';
import { EditOrder } from '../partials';

const VIEWS = {
  SCAN_TICKET: 'SCAN_TICKET',
  VIEW_RESULT: 'VIEW_RESULT',
  VIEW_QR_NOT_FOUND: 'QR_NOT_FOUND',
}

const CameraModal = () => {
  const { orderStore } = useStores();
  const [view, setView] = useState(VIEWS.SCAN_TICKET);
  const [result, setResult] = useState('')

  const handleScan = async data => {
    try {
      if (data) {
        const arr = data.replace('[', '').replace(']', '').split(',');

        console.log(data)

        const object = {
          en: arr[0],
          name: arr[1],
          amount: arr[2],
          order_id: arr[3],
          comment: arr[4],
          payment_status: arr[5]
        };


        if (object.en !== '') {
          const status = await orderStore.getOrderByEnNr(object.en);
          if (status !== 200) {
            setView(VIEWS.VIEW_QR_NOT_FOUND);
            return
          }
        }

        setResult(object);
        setView(VIEWS.VIEW_RESULT)
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleError = err => {
    console.error(err)
  };

  return useObserver(() => {
    const close = () => {
      closeModal("camera")
    };

    return (
      <>
        <Modal
          id="camera"
          className={`${styles.modal} ${view === VIEWS.VIEW_RESULT ? styles.info : ''}`}
          onClose={() => setView(VIEWS.SCAN_TICKET)}
        >
          {
            view === VIEWS.SCAN_TICKET && (
              <>
                <div className="modal-header">
                  <h1 className={styles.title}>Scan de QR code</h1>
                  <i className="fas fa-times" onClick={close} />
                </div>

                <div className="modal-content">
                  <div className={styles.qr}>
                    <QrReader
                      delay={300}
                      onError={handleError}
                      onScan={handleScan}
                      style={{ width: '100%', margin: '0 auto', maxWidth: '500px' }}
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <div className="btn" onClick={close}>Sluit</div>
                </div>
              </>
            )
          }

          {
            view === VIEWS.VIEW_QR_NOT_FOUND && (
              <>
                <div className="modal-header">
                  <h1 className={styles.title}>Bestelling niet gevonden</h1>
                  <i className="fas fa-times" onClick={close} />
                </div>

                <div className="modal-content">
                  <div className={styles.message}>
                    De QR code is niet gekend in ons systeem. Probeer de bestelling manueel te zoeken.
                  </div>
                </div>

                <div className="modal-footer">
                  <div className="btn" onClick={close}>Sluit</div>
                </div>
              </>

            )
          }

          {
            view === VIEWS.VIEW_RESULT && (
              <>
                <EditOrder close="camera" />
              </>
            )
          }
        </Modal>
      </>
    )
  })
}

export default CameraModal;
