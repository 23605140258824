import { useObserver } from 'mobx-react-lite';
import React, {useEffect} from 'react';

import { TimeInput } from '../elements';
import { useStore } from '../../hooks';
import styles from '../../assets/scss/cmsOverview.module.scss';

const Day = ({ day }) => {
  const settingsStore  = useStore('settingsStore');

  const deleteHour = (day, hour) => {
    const { start, stop } = hour;
    settingsStore.deleteHourFromDay(day, start, stop);
  };

  const addHour = day => {
    settingsStore.addHour(day);
  };

  const toggleActive = () => {
    day.active = !day.active;
    settingsStore.setStateFromDay(day);
  };

  return useObserver(() => {
    const { hours, name } = day;

    return (
      <div className={styles.day}>
        <div className={styles.info}>
          <span className={`${styles.status} ${day.active ? styles.active : styles.deactive }`}></span>
          <span>{ name }</span>
          <i 
            className={`${styles.icon} fas ${day.active ? 'fa-lock-open' : 'fa-lock'}`}  
            onClick={toggleActive}
          />
        </div>


        {
          !day.active && <div className={styles.overlay} />
        }


        <div className={styles.content}>
          <ul className={styles.hours}>

            {
              hours.length > 0 && (
                <li className={styles.header}>
                  <span>Van</span>
                  <span>Tot</span>
                </li>
              )
            }
            

            {
              hours.length === 0 && (
                <p className={styles.placeholder}>Deze dag bevat nog geen openingsuren.</p>
              )
            }


            {
              hours.map((hour, index) => {
                return ( 
                  <li key={index}>
                    <span>{hour.start}</span>
                    <span>{hour.stop}</span>
                    <i className="fas fa-trash-alt" onClick={() => deleteHour(day, hour)}/>
                  </li> 
                )
              })
            }

          </ul>
        </div>


        <div>
          <div className={styles.add}>
            <TimeInput entity={day} name="new_start" placeholder="Vul een starttijd in"/>
            <TimeInput entity={day} name="new_stop" placeholder="Vul een stoptijd in"/>
            <i className="fas fa-plus" onClick={() => addHour(day)}/>
          </div>
        </div>
        
      </div>
    )
  })
}

const CmsOverview = () => {
  const settingsStore = useStore('settingsStore');

  useEffect(() => {
    settingsStore.getAllDays();
    settingsStore.getSettings();
  }, [settingsStore]);

  return useObserver(() => {
    const { days } = settingsStore;

    return (
      <main className="cms">
        <section>
          <h2 className="title">Tijdstippen waarop mensen kunnen afhalen</h2>

          <div className={styles.days}>

            {
              days.map((day, index) => (
                <Day day={day} key={index}/>
              ))
            }
          </div>

        </section>


      </main>
    )
  })
}

export default CmsOverview;
