import { useState, useEffect } from 'react';
import styles from '../../assets/scss/calculator.module.scss';

const Calculator = ({ order, setReset }) => {
  const [amounts, setAmounts] = useState({
    firstChoice: 0,
    secondChoice: 0,
    boxes: 0,
    firstChoicePaid: 0
  });

  useEffect(() => {
    setAmounts(() => {
      return {
        firstChoice: order?.amount ?? 0,
        secondChoice: 0,
        boxes: order?.boxes ?? 0,
        firstChoicePaid:
          order?.state === 'paid'
            ? order.amount
            : 0
      }
    })
  }, [order]);

  useEffect(() => {
    if (setReset !== undefined) {
      setReset(() => {
        return () => {
          setAmounts(() => {
            return {
              firstChoice: order?.amount ?? 0,
              secondChoice: 0,
              boxes: order?.boxes ?? 0,
              firstChoicePaid:
                order?.state === 'paid'
                  ? order.amount
                  : 0
            }
          })
        }
      });
    }
  }, []);

  const onAmountChange = (ev) => {
    const { name, value } = ev.target;

    setAmounts((amounts) => {
      return {
        ...amounts,
        [name]: value,
      }
    })
  }

  const firstChoicePrice = 12;
  const secondChoicePrice = 8;

  const total = (amounts.firstChoice * firstChoicePrice) + (amounts.secondChoice * secondChoicePrice) + (amounts.boxes * 1)
  const totalToPay = (amounts.firstChoice * firstChoicePrice) + (amounts.secondChoice * secondChoicePrice) + (amounts.boxes * 1) - (amounts.firstChoicePaid * firstChoicePrice)

  return (
    <div>
      <h2 className={styles.title}>
        Vul de hoeveelheden in:
      </h2>

      <form className={styles.form}>
        <div>
          Aantal eerste keuze:
          <input
            name="firstChoice"
            type="number"
            value={amounts.firstChoice}
            onChange={onAmountChange}
          />
        </div>

        <div>
          Aantal confituur / 0.5kg wit:
        <input
            name="secondChoice"
            type="number"
            value={amounts.secondChoice}
            onChange={onAmountChange}
          />
        </div>

        <div>
          Aantal boxen:
        <input
            name="boxes"
            type="number"
            value={amounts.boxes}
            onChange={onAmountChange}
          />
        </div>
      </form>

      <div className={styles.totalContainer}>
        <div className={styles.total}>
          Het totaal bedrag is: <strong>{total}</strong>
        </div>
        <div className={styles.totalToPay}>
          Nog te betalen: <strong>{totalToPay}</strong>
        </div>
      </div>
    </div>
  )
}

export default Calculator;
