import React, { useState, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useModal } from '../../hooks';
import styles from '../../assets/scss/modal.module.scss';

const Modal = ({ id, className, onClose, onOpen, children, disableFooter = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { register, unregister } = useModal();
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else if (!isOpen) {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }
  }, [isOpen])

  useEffect(() => {
    if (init) {
      if (isOpen && onOpen !== undefined) {
        onOpen();
      }

      if (!isOpen && onClose !== undefined) {
        onClose();
      }
    } else {
      setInit(true);
    }
  }, [isOpen])

  useEffect(() => {
    register(id, setIsOpen);

    return () => {
      unregister(id);
    }
  }, []);

  return useObserver(() => {
    return isOpen && (
      <>
        <div id={id} className={`${styles.modal} ${className}`} style={disableFooter ? { gridTemplateRows: '60px 1fr' } : {}}>
          {children}
        </div>
        <div className={styles.overlay} style={{ zIndex: id === 'warningModal' ? 15004 : 1500 }} />
      </>
    )
  })
};

export default Modal;

