import React from 'react';
import { useObserver } from "mobx-react-lite";
import { Modal } from '../elements';
import { EditOrder } from '../partials';
import styles from '../../assets/scss/editOrder.module.scss'
import { useStores } from '../../hooks';

const EditOrders = () => {
  const { orderStore } = useStores();

  const onClose = () => {
    const { selectedOrder } = orderStore;
    selectedOrder.editing = false
  };

  const onOpen = () => {
    const { selectedOrder } = orderStore;
    selectedOrder.editing = true
  };

  return useObserver(() => {
    return (
      <>
        <Modal id="editOrder" className={styles.modal} onOpen={onOpen} onClose={onClose}>
          <EditOrder />
        </Modal>
      </>
    )
  })
}

export default EditOrders;
