import React, { useState } from 'react';
import { useObserver } from "mobx-react-lite";
import { Modal } from '../elements';
import styles from '../../assets/scss/modal.module.scss'
import { closeModal } from '../../utils';
import { Calculator } from '../partials';

const CalculatorModal = () => {
  const [reset, setReset] = useState();

  return useObserver(() => {
    const close = () => {
      closeModal("calculator")
    };

    const execReset = () => {
      if (reset !== undefined) {
        reset();
      }
    };

    return (
      <>
        <Modal id="calculator" className={`${styles.modal} ${styles.info}`}>
          <div className="modal-header">
            <h1 className={styles.title}>
              Calculator
            </h1>
            <i className="fas fa-times" onClick={close} />
          </div>

          <div className="modal-content">
            <Calculator setReset={setReset} />

            <div className={styles.buttons}>
              <button className={`${styles.btn} ${styles.green}`} onClick={execReset}>
                <i className="fas fa-sync" />
                Reset de calculator
              </button>

              <button className={styles.btn} onClick={close}>
                <i className="fas fa-times" />
                Sluit de calculator
              </button>
            </div>
          </div>
        </Modal>
      </>
    )
  })
}

export default CalculatorModal;
