import { observable, decorate, computed } from 'mobx';
import { doFetch } from '../utils';
import { Form, PickupDate } from '../components/models';

const URLS = {
  GET_PICKUP_TIMES: `/engelendael/settings/pickup_times`
}

class FormStore {
  constructor() {
    this.form = new Form(this.initalizeForm());
    this.pickupDates = [];
    this.loading = true;
    this.enabled = false;
  };

  resetForm = () => {
    this.form = new Form(this.initalizeForm());
  };

  initalizeForm = () => {
    const formData = localStorage.getItem('engelendael_form_data');

    if (formData !== null) {
      try {
        return JSON.parse(formData)
      } catch {
        return formData
      }
    }

    return {};
  };

  updateLocalStorageForm = () => {
    const obj = this.form.toJS();

    delete obj.amount;
    delete obj.message;

    localStorage.setItem('engelendael_form_data', JSON.stringify(obj))
  };

  clearLocalStorageForm = () => {
    localStorage.removeItem('engelendael_form_data');
  };

  getPickupDates = async () => {
    this.loading = true;
    const { data: pickup_dates } = await doFetch(URLS.GET_PICKUP_TIMES, 'GET');
    this.pickupDates = pickup_dates.map(date => new PickupDate(date));
    this.enabled = true;
    this.loading = false;
  };

  refreshPickupDates = async () => {
    const { data: pickup_dates } = await doFetch(URLS.GET_PICKUP_TIMES, 'GET');
    this.pickupDates = pickup_dates.map(date => new PickupDate(date));
  };

  getPickupTimes = date => {
    const pickupDate = this.pickupDates.find(dateData => dateData.date === date);

    if (pickupDate !== undefined) {
      return pickupDate.pickup_times.map(time => ({ text: time, value: time}));
    }

    return [];
  };

  getMaxAmount = date => {
    const pickupDate = this.pickupDates.find(dateData => dateData.date === date);

    if (pickupDate !== undefined) {
      let amount = pickupDate.amount - pickupDate.ordered_amount;

      if (amount > 20) {
        amount = 20;
      }

      return Array(amount).fill(undefined).map((item, index) => ({text: `${index + 1}kg`, value: index + 1}));
    }

    return [];
  }

  get amountOfBoxes() {
    const minAmount = 0;

    return Array(11)
      .fill(undefined)
      .map((_, index) => {
        return ({text: `${index + minAmount} Fruitbox${ index + minAmount === 1 ? '' : 'en'}`, value: index + minAmount })
      });
  }
};

decorate(FormStore, {
  form: observable,
  pickupDates: observable,
  loading: observable,
  enabled: observable,
  amountOfBoxes: computed
});

export default new FormStore();


