import React, { useState } from 'react';
import { useObserver } from 'mobx-react-lite'
import styles from '../../assets/scss/dropdown.module.scss';

const Dropdown = ({ children, className }) => {
  const [state, setState] = useState(false)

  const toggleState = ev => {
    ev.stopPropagation();
    setState(!state);
  };

  const close = ev => {
    ev.stopPropagation();
    setState(false)
  };

  return useObserver(() => {
    return (
      <div className={`${styles.dropdownContainer} ${className}`}>
        <div className={styles.dropdown} onClick={toggleState}>
          <i className="fas fa-ellipsis-h" />
        </div>

        {
          state && (
            <>
              <ul className={styles.dropdownList} onClick={close}>
                {children}
              </ul>
              <div className={styles.dropdownOverlay} onClick={close}/>
            </>
          )
        }
      </div>
    )
  });
};

export default Dropdown;

