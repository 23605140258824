import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { storeContext } from './context';

export const useStore = store => {
  return useContext(storeContext)[store];
};

export const useStores = () => {
  return useContext(storeContext);
};

export const useRedirect = () => {
  const history = useHistory();

  const redirect = location => {
    history.push(location);
  };

  return {
    redirect
  }
};

export const useModal = () => {
  const modalStore = useStore('modalStore');

  const register = (id, cb) => {
    modalStore.registerModal({ id, cb});
  };

  const unregister = id => {
    modalStore.unregisterModal(id);
  };

  return {
    register,
    unregister
  }
}

export const useDebounce = (callback, deps) => {
  const [ result, setResult ] = useState('');
  const [ value, setValue ] = useState(JSON.stringify(deps));
  const [ timeout, setTimeoutHandler ] = useState(null);
  const [ lastTime, setLastTime ] = useState(Date.now());

  const execute = async () => {
    if (lastTime + 500 < Date.now()) {
      const result = await callback()
      setResult(result);
    } else {
      if (timeout !== null) {
        clearTimeout(timeout);
      }

      const newTimeout = setTimeout(execute, (lastTime + 500 - Date.now())) 
      setTimeoutHandler(newTimeout)
    }

    setLastTime(Date.now());
  };

  useEffect(() => {
    execute();
  } , [value]);

  useEffect(() => {
    setValue(JSON.stringify(deps))
  }, deps)

  return {
    result,
  };
}



export const useError = defaultState => {
  const [error, setError] = useState(defaultState);
  const [errors, setErrors] = useState([]);

  const clearError = () => {
    if (error !== '') {
      setError('');
      setErrors([]);
    }
  };

  const setHtmlError = error => {
    if (!Array.isArray(error)) {
      console.log(error);
    }

    const writeErrors = () => {
      const content = (
        <div>
          <h2 className="errortitle">
            <FormattedMessage id="please_resolve_the_following_errors" />
          </h2>
          <ul>
            {
              error.map(error => (
                <li>
                  <FormattedMessage id={error.error} />
                </li>
              ))
            }
          </ul>
        </div>
      );

      setErrors(error);
      setError(content);
    }

    const currentErrors = JSON.stringify(errors);
    const newErrors = JSON.stringify(error);

    if (currentErrors !== newErrors) {
      writeErrors();
    }
  };

  return {
    error,
    clearError,
    setHtmlError
  }
}

export const useUnmount = cb => {
  return useEffect(() => {
    return cb
  }, []);
};

export const useReset = () => {
  const authStore = useStore('authStore');
  useEffect(() => {
    console.log('hierin');
    authStore.resetAccount();
  }, []);
};


export const useWidthValidator = () => {
  const width = window.innerWidth;
  const [ isMobile, setIsMobile ] = useState(width <= 800);
  const [ windowWidth, setWindowWidth ] = useState(width);

  useEffect(() => {
    const setWidth = () => {
      const width = window.innerWidth;
      setWindowWidth(width);

      if (width <= 800 && !isMobile) {
        setIsMobile(true)
      } else if (width >= 800 && isMobile) {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', setWidth);

    return () => {
      window.removeEventListener('resize', setWidth);
    }
  }, [isMobile]);

  return {
    isMobile,
    width: windowWidth,
  };
};
