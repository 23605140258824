import { observable, decorate } from 'mobx';
import { Param as param, Validator } from './';

class DayOverview {
  constructor(data) {
    this.validate = new Validator();
    this.totalStock = param(data, 'total_stock');
    this.finishedStock = param(data, 'finished_stock');
    this.requiredStock = param(data, 'required_stock');
    this.amountOfOrders = param(data, 'amount_of_orders');
    this.totalOnlinePaidOrders = param(data, 'total_online_paid_orders');
    this.totalCashPaidOrders = param(data, 'total_cash_paid_orders');
    this.totalCardPaidOrders = param(data, 'total_card_paid_orders');
    this.amountPaidOnline = param(data, 'amount_paid_online');
    this.amountPaidWithCash = param(data, 'amount_paid_with_cash');
    this.amountPaidWithCard = param(data, 'amount_paid_with_card');
    this.ordersLeft = param(data, 'orders_left');

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  }

  toJS = () => {
    return {
      totalStock: this.totalStock,
      finishedStock: this.finishedStock,
      requiredStock: this.requiredStock,
      amountOfOrders: this.amountOfOrders,
      totalOnlinePaidOrders: this.totalOnlinePaidOrders,
      ordersLeft: this.ordersLeft,
    }
  }
};

decorate(DayOverview, {
  totalStock: observable,
  finishedStock: observable,
  requiredStock: observable,
  amountOfOrders: observable,
  totalOnlinePaidOrders: observable,
  amountPaidOnline: observable,
  amountPaidWithCard: observable,
  amountPaidWithCash: observable,
  
  ordersLeft: observable,
});

export default DayOverview

