import React, { useState, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { observe } from 'mobx';
import { useStores, useDebounce, useRedirect } from '../../hooks';

import { EditOrderModal, AddOrderModal } from '../modals';
import { openModal } from '../../utils';
import { List, Dropdown, DateInput } from '../elements';

import cherryIcon from '../../assets/icons/cherryIcon.svg'
import styles from '../../assets/scss/orders.module.scss';

const VIEWS = {
  INCLUDE_COMPLETED_ORDERS: 'INCLUDE_COMPLETED_ORDERS',
  HIDE_COMPLETED_ORDERS: 'HIDE_COMPLETED_ORDERS',
}

const getColor = (state, paid_with) => {
  switch(state.toLowerCase()) {
    case 'unpaid':
      return 'red';

    case 'paid':
      if (paid_with === 'mollie') {
        return 'orange';
      } else {
        return '#2eab6f';
      }

    case 'canceled':
      return 'inherit';

    default:
      return 'inherit';
  }
};

const getTranslation = (state, paid_with) => {
  switch(state.toLowerCase()) {
    case 'unpaid':
      return 'Onbetaald';

    case 'paid':
      if (paid_with === 'mollie') {
        return 'Online betaald'
      } else {
        return 'Opgehaald';
      }

    case 'reserved':
      return 'In wacht'

    case 'canceled':
      return 'Geannuleerd';

    case 'manual':
      return 'Manueel';

    default:
      return state;
  }
};


const Orders = () => {
  const [searchString, setSearchString] = useState('');
  const hide = localStorage.getItem("hide")
  const [view, setView] = useState(hide !== null && hide === 'true' ? VIEWS.HIDE_COMPLETED_ORDERS : VIEWS.INCLUDE_COMPLETED_ORDERS);
  console.log(view)
  const { orderStore } = useStores();
  const { period } = useParams();

  useEffect(() => {
  }, [orderStore, period])

  useDebounce(() => {
    if (searchString.length >= 2) {
      orderStore.getAllOrdersWithSearch(searchString);
    }
  }, [searchString]);

  useEffect(() => {
    const dispose = observe(orderStore.sortSettings, 'sortMethod', () => {
      orderStore.getAllOrdersWithSearch(searchString);
    }, true);

    return () => {
      dispose();
    };

  }, [orderStore, searchString]);

  useEffect(() => {
    orderStore.getAllOrdersWithSearch(searchString);
  }, [orderStore, searchString, orderStore.selectedPeriod, orderStore.specificDate])

  const afterChange = () => {
    console.log("after change")
    orderStore.getAllOrdersWithSearch(searchString);
  };

  const changeFilter = ev => {
    setSearchString(ev.target.value);
    orderStore.searchString = ev.target.value;
  };

  const editOrder = (order) => {
    orderStore.selectedOrder = order;
    openModal('editOrder');
  };

  const toggleCompletedOrders = () => {
    if (view === VIEWS.HIDE_COMPLETED_ORDERS) {
      localStorage.setItem('hide', false)
      setView(VIEWS.INCLUDE_COMPLETED_ORDERS)
    } else {
      localStorage.setItem('hide', true)
      setView(VIEWS.HIDE_COMPLETED_ORDERS)
    }
  };

  const openAddOrder = () => {
    openModal('addOrder');
  };

  const storeSelectedPeriod = ({ newValue }) => {
    if (localStorage !== null ) { 
      localStorage.setItem('orders_period', newValue)
    }
  };

  return useObserver(() => {
    const { orders, periods } = orderStore;

    const ordersToShow = orders.filter(order => {
      if (view === VIEWS.HIDE_COMPLETED_ORDERS) {
        return order.state === 'unpaid' || order.state === 'manual' || order.paid_with === 'mollie' || order.state === 'reserved'
      }

      return true
    })

    return (
      <>
        <EditOrderModal />
        <AddOrderModal />

        <main className="cms">
          <div className={styles.flexHeader}>
            <h1 className="title">Bestellingsoverzicht</h1>
            <Dropdown className={styles.dropdown}>
              <li onClick={openAddOrder}>Voeg toe</li>
              <li onClick={toggleCompletedOrders}>
                {
                  view === VIEWS.HIDE_COMPLETED_ORDERS && (
                    <span>Toon afgewerkte bestellingen</span>
                  )
                }

                {
                  view === VIEWS.INCLUDE_COMPLETED_ORDERS && (
                    <span>Verberg afgewerkte bestellingen</span>
                  )
                }
              </li>
            </Dropdown>
          </div>

          <div className={styles.filterContainer}>
            <div>
              <label htmlFor="">Toon bestellingen van</label>
              <List 
                entity={orderStore} 
                list={periods} 
                value="id" 
                text="name" 
                name="selectedPeriod" 
                afterChange={storeSelectedPeriod}
              />
            </div>

            {
              parseInt(orderStore.selectedPeriod) === 10 && (
                <div>
                  <label htmlFor="from">Datum</label>
                  <DateInput 
                    entity={orderStore} 
                    name="specificDate" 
                    afterChange={afterChange}
                  />
                </div> 
              )
            }

            <div>
              <label htmlFor="">Filter met een zoekterm</label>
              <input 
                type="text" 
                placeholder="Bv: George, EN40, 24/07, ..." 
                onChange={changeFilter}
              />
            </div>

            
          </div>

          {
            ordersToShow.length === 0 && (
              <div className={styles.placeholder}>
                Er zijn geen bestellingen gevonden voor de gekozen periode.
              </div>
            )
          }


          {
            ordersToShow.length > 0 && (
              <div className={styles.ordersContainer}>
                <ul className={`${styles.orders} ${styles.collection} collection`}>
                  {
                    ordersToShow.map(order => (
                      <>
                        <li key={order.EN_nr} onClick={() => editOrder(order)}>
                          <div className={styles.order}>
                            <div className={styles.ENnr}>
                              <span style={{color: getColor(order.state, order.paid_with)}}>{order.EN_nr}</span>
                              <span className={styles.state} style={{color: getColor(order.state, order.paid_with)}}>{getTranslation(order.state, order.paid_with)}</span>
                            </div>

                            <div className={styles.grid}>
                              <span className={styles.cherryImgContainer}>
                                <img src={cherryIcon} className={styles.cherryImg}/>
                                <span>{order.amount}kg</span>
                              </span>

                              <span>
                                <i className="fas fa-envelope" />
                                <span>{order.email !== '' ? order.email : '---'}</span>
                              </span>

                              <span>
                                <i className="fas fa-calendar-alt" />
                                <span>{order.date}</span>
                              </span>

                              <span>
                                <i className="fas fa-user"/>
                                <span>{order.name}</span>
                              </span>
                            </div>
                          </div>
                          

                          

                          {
                            order.message !== '' && (
                              <div className={styles.message}>
                                <i className="fas fa-comment-alt"></i>
                                <span>
                                  <div>
                                    {
                                      order.message
                                    }
                                  </div>

                                </span> 
                              </div>
                            )
                          }

                        </li>
                      </> 
                    ))
                  } 
                </ul>
              </div>
            )
          }
        </main>  
      </>
    )
  });
};

export default Orders; 
