import React, {useEffect} from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Overview , Orders, Stock, History, Scanner, Home, DayOverview } from './pages';
import { getTokenInfo } from '../utils';
import {useStore} from "../hooks";
import { Sidenav } from "./partials";
import { CameraModal } from "./modals";
import { Footer } from "./global";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = getTokenInfo();
  const orderStore = useStore('orderStore');

  useEffect(() => {
    orderStore.selectedOrder = undefined;
  }, [])

  if (token.expiring < Date.now() / 1000) {
    window.location.href = 'https://sso.engelendael.be';
  }

  return (
    <Route {...rest} render={(props) => <Component {...props} />} />
  );
}

const Router = () => {
  return (
    <BrowserRouter>
      <CameraModal />
      <Sidenav />
      <Switch>
        <PrivateRoute exact path="/home" component={Home}/>
        <PrivateRoute exact path="/scanner" component={Scanner}/>
        <PrivateRoute exact path="/overview/day" component={DayOverview}/>
        <PrivateRoute exact path="/settings" component={Overview}/>
        <PrivateRoute exact path="/orders" component={Orders}/>
        <PrivateRoute exact path="/orders/:period" component={Orders}/>
        <PrivateRoute exact path="/history" component={History}/>
        <PrivateRoute exact path="/stock" component={Stock}/>
        <PrivateRoute exact path="/stock" component={Stock}/>

        <Route path="*" component={() => <Redirect to="/home"/> } />
      </Switch>
      <Footer />
    </BrowserRouter>
  )
}

export default Router;
