import { observable, decorate } from 'mobx';
import { doFetch } from '../utils';
import { Account } from '../components/models';

const URLS = {
  LOGOUT: `/engelendael/auth/logout`,
};

class AuthStore {
  constructor() {
    this.account = new Account();
  }

  logout = () => {
    return doFetch(URLS.LOGOUT, 'POST');
  };
};

decorate(AuthStore, {
  account: observable
});

export default new AuthStore();

