import { observable, decorate } from 'mobx';
import { Param as param, Validator } from './';

class PickupTime {
  constructor(data) {
    this.validate = new Validator();
    this.id = param(data, 'id');
    this.date = param(data, 'date');
    this.day = param(data, 'day');
    this.day_name = param(data, 'day_name');
    this.ordered_amount = param(data, 'ordered_amount');
    this.amount = param(data, 'stock');
    this.pickup_times = param(data, 'pickup_times', []);
    this.day_name = param(data, 'name');

    this.displayDate = `${this.date} - ${this.day_name}`

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  }

  toJS = () => {
    return {
      id: this.id,
      date: this.date,
      day: this.day,
      day_name: this.day_name,
      pickup_times: this.pickup_times,
    }
  }
}
decorate(PickupTime, {
  id: observable,
  date: observable,
  day: observable,
  day_name: observable,
  pickup_times: observable,
})

export default PickupTime


