import { observable, decorate, observe } from 'mobx';
import { Order, SortSettings, DateConfig } from '../components/models';
import { doFetch } from '../utils';
import moment from 'moment';

class Period {
  constructor(name, id) {
    this.name = name
    this.id = id
  }
}


const URLS = {
  GET_ALL_ORDERS_FOR_DAY: `/engelendael/orders/day`,
  UPDATE_ORDER_STATE: `/engelendael/orders/state`,
  ORDER_CHERRIES: `/engelendael/orders/order/manual`,
  GET_ORDER_BY_EN_NR: ENnr => `/engelendael/orders/${ENnr}`,
  SAVE_SELECTED_ORDER: ENnr => `/engelendael/orders/${ENnr}`,
  GET_STOCK_FOR_DAY: `/engelendael/orders/day/stock`,
  GET_ALL_ORDERS_WITH_SEARCH: `/engelendael/orders/search`,
};

class OrderStore {
  constructor() {
    this.orders = [];
    this.ordersToRender = [];
    this.newOrder = new Order();
    this.selectedOrder = new Order();
    this.sortSettings = new SortSettings();
    this.dateConfig = new DateConfig('order');
    this.specificDate = moment(Date.now()).format('DD/MM/YYYY');
    this.searchString = "";

    this.periods = [
      new Period('Vorige maand', 1),
      new Period('Vorige week', 2),
      new Period('Gisteren', 3),
      new Period('Vandaag', 4),
      new Period('Morgen', 5),
      new Period('Deze week', 6),
      new Period('Deze maand', 7),
      new Period('Volgende week', 8),
      new Period('Volgende maand', 9),
      new Period('Specifieke datum', 10),
    ]

    this.selectedPeriod = 4;
    if (localStorage !== null) {
      const period = localStorage.getItem('orders_period')
      this.selectedPeriod = parseInt(period) || 4
    }

    observe(this.newOrder, 'date', async () => {
      // const res = await doFetch(URLS.GET_STOCK_FOR_DAY, 'POST', {day: newValue});
      // this.newOrder.leftoverStock = res.leftoverStock;
    }, false);

    setInterval(async () => {
      const { data: orders, status } = await doFetch(URLS.GET_ALL_ORDERS_WITH_SEARCH, 'POST', {
        searchstring: this.searchString,
        period: parseInt(this.selectedPeriod) || 4,
        date: this.specificDate,
      });

      if (status === 200) {
        orders.forEach(order => {
          const match = this.orders.find(({ EN_nr }) => order.EN_nr === EN_nr)

          if (match !== undefined) {
            match.state = order.state;
            match.paid_with = order.paid_with
            if (!match.editing) {
              match.amount = order.amount
              match.boxes = order.boxes
            }
          }
        })
      }
    }, 10000)
  };

  addOrder = async () => {
    const { data } = await doFetch(URLS.ORDER_CHERRIES, 'POST', {
      ...this.newOrder.toJS(),
      payment_method: 'manual',
    });

    if (this.newOrder.date === moment().format('DD/MM/YYYY')) {
      this.orders.push(new Order({ ...this.newOrder.toJS(), state: 'manual', EN_nr: data.en_nr }));
    }

    this.newOrder = new Order();

    return data;
  };

  getAllOrdersForDay = async (day, filters = []) => {
    const { data: orders } = await doFetch(URLS.GET_ALL_ORDERS_FOR_DAY(), 'POST', {
      day,
      filters
    });

    return orders.map(order => new Order(order));
  };

  getAllOrdersWithSearch = async searchstring => {
    const { data: orders, status } = await doFetch(URLS.GET_ALL_ORDERS_WITH_SEARCH, 'POST', {
      searchstring,
      period: parseInt(this.selectedPeriod) || 4,
      date: this.specificDate,
    });

    if (status === 200) {
      this.orders = orders.map(order => new Order(order));
    }
  };

  setState = async state => {
    const activeOrders = this.orders.filter(order => order.active);

    await doFetch(URLS.UPDATE_ORDER_STATE, 'PUT', {
      orders: activeOrders.map(order => order.EN_nr),
      state
    });

    activeOrders.forEach(order => {
      order.state = state;
      order.active = false;
    });
  };


  markOrderAs = async (order, state, paidWith) => {
    await doFetch(URLS.UPDATE_ORDER_STATE, 'PUT', {
      en_nr: order.EN_nr,
      state,
      paid_with: paidWith,
    })

    order.paid_with = paidWith;
    order.state = state;
  };

  getOrderByEnNr = async ENnr => {
    const { data: order, status } = await doFetch(URLS.GET_ORDER_BY_EN_NR(ENnr), 'GET');
    this.selectedOrder = new Order(order);

    return status
  };

  saveSelectedOrder = () => {
    const { EN_nr } = this.selectedOrder
    return doFetch(URLS.SAVE_SELECTED_ORDER(EN_nr), 'PUT', this.selectedOrder.toJS());
  };
};

decorate(OrderStore, {
  orders: observable,
  newOrder: observable,
  sortMethod: observable,
  selectedOrder: observable,
  dateConfig: observable,
  selectedPeriod: observable,
  specificDate: observable,
  searchString: observable,
});

export default new OrderStore();

