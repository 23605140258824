import { observable, decorate } from 'mobx';
import { Param as param, Validator } from './';

class Day {
  constructor(data) {
    this.validate = new Validator();
    this.id = param(data, 'id');
    this.name = param(data, 'name');
    this.active = param(data, 'active');
    this.hours = param(data, 'opening_hours', []);
    this.new_start = param(data, 'new_start');
    this.new_stop = param(data, 'new_stop');

    this.validation = {};
    this.validate.initializeErrors(this, this.validation);
  };

  toJS = () => {
    return {
      id: this.id,
      name: this.name,
      active: this.active,
      hours: this.hours,
    }
  }
}

decorate(Day, {
  id: observable,
  name: observable,
  active: observable,
  hours: observable,
  new_stop: observable,
  new_start: observable
});

export default Day;

