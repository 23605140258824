import React, { useState } from 'react';
import { useObserver } from "mobx-react-lite";
import { Modal } from '../elements';
import { closeModal, controlOnErrors } from '../../utils';
import { useStores } from '../../hooks';

import styles from '../../assets/scss/addOrder.module.scss'
import { OrderForm } from '../forms';

const Button = ({ className, onClick, text, icon }) => {
  return (
    <div 
      className={`${styles.btn} ${className}`}
      onClick={onClick}
    >
      <>
        {
          icon !== undefined && (
            <i className={icon} />
          )
        }

        { text }
      </>
    </div>
  )
}

const VIEWS = {
  ADD_INFO: 'ADD_INFO',
  ADDED: 'ADDED',
}

const AddOrders = () => {
  const [view, setView] = useState(VIEWS.ADD_INFO)
  const { orderStore } = useStores();

  const saveOrder = async () => {
    const { newOrder } = orderStore;

    try {
      await controlOnErrors(newOrder)
      await orderStore.addOrder()
      setView(VIEWS.ADDED)
    } catch (err) {
      console.log(err)
    }
  };

  const goBack = () => {
    setView(VIEWS.ADD_INFO)
    closeModal('addOrder')
  };

  const onEnter = (ev) => {
    if (ev.key === 'Enter') {
      saveOrder()
    }
  };


  return useObserver(() => {
    const { newOrder } = orderStore;

    return (
      <>
        <Modal id="addOrder" className={`${styles.modal} ${view === VIEWS.ADDED ? styles.added : ''}`} onClose={goBack}>
          <div className="modal-header">
            <h2 className={`${styles.title}`}>
              Nieuwe bestelling
            </h2>

            <i className="fas fa-times" onClick={goBack}/>
          </div>

          <div className="modal-content" onKeyDown={onEnter}>
            {
              view  === VIEWS.ADD_INFO && (
                <OrderForm order={newOrder} />
              )
            }

            {
              view === VIEWS.ADDED && (
                <>
                  <div className={styles.info}>
                    De bestelling is succesvol toegevoegd. Kies één van de 
                    onderstaande opties om verder te gaan
                  </div>

                  <div className={styles.mainActions}>
                    <Button 
                      text="Voeg nieuwe bestelling toe" 
                      onClick={() => setView(VIEWS.ADD_INFO)}
                    />
                    <Button 
                      className={styles.close}
                      text="Sluit scherm" 
                      onClick={goBack}
                    />
                  </div>
                </>
              )
            }
          </div>
          {
            view === VIEWS.ADD_INFO && (
              <div className="modal-footer">
                <Button text="Voeg bestelling toe" onClick={saveOrder}/>
                {/* <Button */} 
                {/*   className={styles.close} */}
                {/*   text="Sluit scherm" */} 
                {/*   onClick={goBack} */}
                {/* /> */}
              </div>
            )
          }
        </Modal>
      </>
    )
  })
}

export default AddOrders;
