import { decorate, observable } from "mobx";

class ModalStore {
  constructor() {
    this.activeModals = [];
    this.registeredModals = [];
  }

  registerModal = modalObject => {
    this.registeredModals.push(modalObject);
  };

  unregisterModal = modalId => {
    this.registeredModals = this.registeredModals.filter(modal => modal.id !== modalId);
  };

  openModal = modal => {
    const match = this.registeredModals.find(modalData => modalData.id === modal);

    if (match !== undefined) {
      match.cb(true);
      this.activeModals.push(match);
      this.isModalOpen = true;
    }
  };

  closeModal = modal => {
    const match = this.registeredModals.find(modalData => modalData.id === modal);

    if (match !== undefined) {
      match.cb(false);
      this.activeModals = this.activeModals.filter(activeModal => activeModal.id !== modal);
      this.isModalOpen = this.activeModals.length > 0;
    }
  };

  closeModalWithOverlay = () => {
    if (this.activeModals.length > 0) {
      this.activeModals.forEach(modal => {
        modal.cb(false);
      });

      this.isModalOpen = false;
    }
  };
}

decorate(ModalStore, {
  activeModals: observable,
  registeredModals: observable
});

export default new ModalStore();

