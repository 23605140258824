import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { openModal } from '../../utils';

import styles from '../../assets/scss/footer.module.scss';
import { when } from 'mobx';
import { Calculator } from '../modals';

const Footer = () => {
  const { pathname } = useLocation();
  const [overlayOpen, setOverlayState] = useState();

  const openScanner = () => {
    openModal('camera')
  };

  const isActive = path => {
    if (path === '/settingsOverlay') {
      const possibleMatches = ['/settings', '/stock']
      const active = possibleMatches.find(path => {
        return pathname.includes(path)
      })

      return active ? styles.active : undefined;
    }

    return pathname.includes(path) ? styles.active : undefined;
  };

  const toggleOverlay = () => {
    setOverlayState(!overlayOpen)
  };

  const closeOverlay = () => {
    setOverlayState(false);
  };

  const logout = () => {
    console.log('logout')
  };

  const openCalculator = () => {
    openModal('calculator');
  }

  return (
    <>
      {
        overlayOpen && (
          <>
            <div className={styles.overlay} onClick={() => setOverlayState(false)}>
            </div>
          </>
        )
      }

      <Calculator />

      <div className={styles.overlayContent} style={{ maxHeight: overlayOpen ? '300px' : '0px' }}>
        <ul className={styles.overlayIcons}>
          <li className={isActive('/settings')} onClick={closeOverlay}>
            <Link to="/settings">
              <i className="fas fa-list" />
              <span>Openingsuren</span>
            </Link>
          </li>

          <li className={isActive('/stock')} onClick={closeOverlay}>
            <Link to="/stock">
              <i className="fas fa-balance-scale-right" />
              <span>Stockbeheer</span>
            </Link>
          </li>

          <li className={isActive('/home')}>
            <Link to="/home">
              <i className="fas fa-columns" />
              <span>
                Dashboard
              </span>
            </Link>
          </li>

          <li onClick={logout}>
            <a href="#">
              <i className="fas fa-sign-out-alt" />
              <span>
                Logout
              </span>
            </a>
          </li>
        </ul>
      </div>

      <footer className={styles.footer}>
        <ul className={styles.links}>
          <li className={isActive('/orders')}>
            <Link to="/orders">
              <i className="fas fa-list" />
              <span>Bestellingen</span>
            </Link>
          </li>

          <li className={isActive('/overview/day')}>
            <Link to="/overview/day">
              <i className="fas fa-chart-line" />
              <span>Overzicht dag</span>
            </Link>
          </li>

          <div onClick={openScanner} className={styles.openScanner}>
            <i className="fas fa-camera" />
          </div>

          <li className={isActive('/home')} onClick={openCalculator}>
            <a href="#">
              <i className="fas fa-calculator" />
              <span>
                Calculator
              </span>
            </a>
          </li>

          <li className={isActive('/settingsOverlay')} onClick={toggleOverlay}>
            <a href="#" to="/settings">
              <i className="fas fa-cog" />
              <span>
                Instellingen
              </span>
            </a>
          </li>
        </ul>
      </footer>
    </>
  )
}

export default Footer;
