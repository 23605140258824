import React from 'react';
import { useObserver } from "mobx-react-lite";
import { useStores } from '../../hooks';
import { Label, Input, Textarea, DateInput } from '../elements/';
import styles from '../../assets/scss/editOrder.module.scss';

const EditOrderForm = () => {
  const { orderStore} = useStores();

  return useObserver(() => {
    const { selectedOrder } = orderStore
    const { paid_with, state } = selectedOrder

    let orderIsPaid = false;

    if (paid_with === 'mollie' || state === 'paid') {
      orderIsPaid = true
    }

    return (
      <>
        {
          orderIsPaid && (
            <div className={styles.warningMessage} style={{marginBottom: '20px'}}>
              De hoeveelheid van deze bestelling kunt u niet aanpassen gezien deze reeds betaald is.
            </div>
          )
        }

        <div className={styles.grid}>
          <div>
            <Label htmlFor="Naam"/>
            <Input entity={selectedOrder} name="name" />
          </div>

          <div>
            <Label htmlFor="Email"/>
            <Input entity={selectedOrder} name="email" />
          </div>

          <div>
            <Label htmlFor="Adres"/>
            <Input entity={selectedOrder} name="address" />
          </div>

          <div>
            <Label htmlFor="Telefoon nummer"/>
            <Input entity={selectedOrder} name="telno" />
          </div>

          <div>
            <Label htmlFor="Datum"/>
            <DateInput entity={selectedOrder} name="date" />
          </div>

          {
            !orderIsPaid && (
              <>
                <div>
                  <Label htmlFor="Hoeveelheid kersen"/>
                  <Input entity={selectedOrder} name="amount" min={1} max={20} type="number" />
                </div>
                <div>
                  <Label htmlFor="Hoeveelheid boxen"/>
                  <Input entity={selectedOrder} name="boxes" min={0} max={20} type="number" />
                </div>
              </>
            )
          }
        </div>

        <div>
          <Label htmlFor="Opmerking"/>
          <Textarea entity={selectedOrder} name="message" />
        </div>
      </>
    )
  })
}

export default EditOrderForm
