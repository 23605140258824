import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { useStores } from '../../hooks';

import engelendaelLogoDark from '../../assets/images/logo.svg'
import styles from '../../assets/scss/aside.module.scss';
import { openModal } from '../../utils';
import { Calculator } from '../modals';

const Sidenav = () => {
  const { authStore } = useStores();
  const { pathname } = useLocation();

  const isActive = path => {
    return pathname.includes(path) ? styles.active : undefined;
  };

  const logout = async () => {
    await authStore.logout();
    window.location.href = 'https://sso.engelendael.be';
  };

  const openScanner = () => {
    openModal('camera');
  };

  const openCalculator = () => {
    openModal('calculator');
  };

  return useObserver(() => {
    return (
      <>
        <Calculator />
        <aside>
          <div className={styles.logo}>
            <Link to="/">
              <img src={engelendaelLogoDark} alt="Engelendael logo" />
            </Link>
          </div>

          <ul className={styles.links}>
            <li className={isActive('/home')}>
              <Link to="/home">
                <i className="fas fa-columns" />
                <span>
                  Dashboard
              </span>
              </Link>
            </li>

            <li className={isActive('/settings')}>
              <Link to="/settings">
                <i className="fas fa-cog" />
                <span>
                  Instellingen
              </span>
              </Link>
            </li>
            <li className={isActive('/overview/day')}>
              <Link to="/overview/day">
                <i className="fas fa-chart-line" />
                <span>Overzicht dag</span>
              </Link>
            </li>
            <li className={isActive('/stock')}>
              <Link to="/stock">
                <i className="fas fa-balance-scale-right" />
                <span>Stockbeheer</span>
              </Link>
            </li>
            <li className={isActive('/orders')}>
              <Link to="/orders">
                <i className="fas fa-list" />
                <span>Bestellingen</span>
              </Link>
            </li>

            <li className={isActive('/logout')} onClick={logout}>
              <a href="#">
                <i className="fas fa-sign-out-alt"></i>
                <span>Logout</span>
              </a>
            </li>

            <div onClick={openCalculator} className={styles.openCalculator}>
              <i className="fas fa-calculator" />
            Calculator
          </div>

            <div onClick={openScanner} className={styles.openScanner}>
              <i className="fas fa-camera" />
            Scanner
          </div>
          </ul>
        </aside>
      </>
    )
  })
};

export default Sidenav;
