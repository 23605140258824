import React, { useState } from 'react';
import { useObserver } from "mobx-react-lite";

import { closeModal } from '../../utils';
import { Messages } from '../I18n'
import { useStores } from '../../hooks';
import { EditOrderForm, MinifiedOrderForm } from '../forms/'
import { Calculator } from '../partials';

import cherryIcon from '../../assets/icons/cherryIcon.svg'
import styles from '../../assets/scss/editOrder.module.scss'


const Button = ({ className, onClick, text, icon }) => {
  return (
    <div
      className={`${styles.btn} ${className}`}
      onClick={onClick}
    >
      <>
        {
          icon !== undefined && (
            <i className={icon} />
          )
        }

        {text}
      </>
    </div>
  )
}

const VIEWS = {
  WARNING: 'WARNING',
  ORDER_INFO: 'ORDER_INFO',
  CONTROLS: 'CONTROLS',
  EDIT_INFO: 'EDIT_INFO',
  ADD_ORDER: 'ADD_ORDER',
}

const TABS = {
  INFO: 'INFO',
  CALCULATOR: 'CALCULATOR',
}

const EditOrder = ({ close }) => {
  const [view, setView] = useState(VIEWS.CONTROLS);
  const [selectedTab, setSelectedTab] = useState(TABS.INFO);
  const { orderStore } = useStores();
  const [reset, setReset] = useState();

  const closeEditOrders = () => {
    closeModal(close || 'editOrder')
  };

  const execReset = () => {
    if (reset !== undefined) {
      reset();
    }
  };


  const getColor = (state, paid_with) => {
    switch (state?.toLowerCase()) {
      case 'unpaid':
        return 'red';

      case 'paid':
        if (paid_with === 'mollie') {
          return 'orange';
        } else {
          return '#2eab6f';
        }

      case 'reserved':
        return 'orange'

      case 'canceled':
        return 'red';

      default:
        return 'inherit';
    }
  };

  const getPaidWithTranslation = (paidWith) => {
    switch (paidWith?.toLowerCase()) {
      case 'card':
        return 'de kaart';

      case 'cash':
        return 'cash';

      case 'mollie_finished':
        return 'mollie';
    }
  }

  const getTranslation = (state, paid_with) => {
    switch (state?.toLowerCase()) {
      case 'unpaid':
        return 'Te betalen';

      case 'paid':
        if (paid_with === 'mollie') {
          return 'Online betaald'
        } else {
          return 'Opgehaald';
        }

      case 'reserved':
        return 'Deze bestelling is in behandeling'

      case 'canceled':
        return 'Deze bestelling is geannuleerd';

      case 'manual':
        return 'Manueel';

      default:
        return state;
    }
  };

  const markAsPaidWithCash = () => {
    const { selectedOrder } = orderStore;
    orderStore.markOrderAs(selectedOrder, 'paid', 'cash')
    Messages.success('order status veranderd.')
  };

  const markAsPaidWithCard = () => {
    const { selectedOrder } = orderStore;
    orderStore.markOrderAs(selectedOrder, 'paid', 'card')
    Messages.success('order status veranderd.')
  };

  const completeMollieOrder = () => {
    const { selectedOrder } = orderStore;
    orderStore.markOrderAs(selectedOrder, 'paid', 'mollie_finished');
    Messages.success('order status veranderd.')
  };

  const markAsUnpaid = () => {
    const { selectedOrder } = orderStore;
    if (selectedOrder.paid_with === 'mollie_finished') {
      orderStore.markOrderAs(selectedOrder, 'paid', 'mollie');
    } else if (selectedOrder.mollie_id === 'MANUAL') {
      orderStore.markOrderAs(selectedOrder, 'manual', 'unpaid');
    } else {
      orderStore.markOrderAs(selectedOrder, 'unpaid', 'unpaid');
    }
    Messages.success('order status veranderd.')
    setView(VIEWS.CONTROLS)
  };

  const cancelOrder = () => {
    const { selectedOrder } = orderStore;
    orderStore.markOrderAs(selectedOrder, 'canceled', 'unpaid');
    setView(VIEWS.CONTROLS);
    Messages.success('De bestelling is geannuleerd')
  };

  const displayWarningForUnpaid = () => {
    setView(VIEWS.WARNING);
  };

  const goBack = () => {
    setView(VIEWS.CONTROLS);
  };

  const showOrderInfo = () => {
    setView(VIEWS.ORDER_INFO);
  };

  const showEditInfo = () => {
    setView(VIEWS.EDIT_INFO)
  };

  const addOrder = () => {
    const { selectedOrder, newOrder } = orderStore;

    newOrder.name = selectedOrder.name
    newOrder.email = selectedOrder.email
    newOrder.telno = selectedOrder.telno

    setView(VIEWS.ADD_ORDER)
  };

  const saveChanges = async () => {
    // TODO: validate selected order
    try {
      await orderStore.saveSelectedOrder()
      setView(VIEWS.ORDER_INFO)
      Messages.success("De gegevens zijn succesvol aangepast.")
    } catch {
      console.log('something went wrong')
    }
  };

  const addNewOrder = async () => {
    try {
      setView(VIEWS.CONTROLS)
      await orderStore.addOrder()
      Messages.success("De nieuwe bestelling is toegevoegd.")
    } catch (err) {
      console.log(err)
      Messages.error("Er is iets fout gegaan tijdens het toevoegen van de nieuwe bestelling.")
    }
  };

  return useObserver(() => {
    const { selectedOrder, newOrder } = orderStore;

    return (
      <>
        {
          view === VIEWS.ADD_ORDER && (
            <>
              <div className="modal-header">
                <h2 className={`${styles.title}`}>
                  Nieuwe bestelling
                </h2>

                <i className="fas fa-times" onClick={goBack} />
              </div>

              <div className="modal-content">
                <MinifiedOrderForm order={newOrder} />

                <div className={styles.secondActions}>
                  <Button
                    className={`${styles.btn} ${styles.green}`}
                    onClick={addNewOrder}
                    icon="fas fa-plus"
                    text="Voeg bestelling toe"
                  />

                  <Button
                    className={styles.btn}
                    onClick={goBack}
                    icon="fas fa-times"
                    text="Ga terug"
                  />
                </div>
              </div>
            </>
          )
        }
        {
          view === VIEWS.EDIT_INFO && (
            <>
              <div className="modal-header">
                <h2 className={`${styles.title}`}>
                  {selectedOrder?.EN_nr} - {selectedOrder?.date}
                </h2>

                <i className="fas fa-times" onClick={goBack} />
              </div>

              <div className="modal-content">
                <EditOrderForm />

                <div className={styles.secondActions}>
                  <Button
                    className={`${styles.btn} ${styles.green}`}
                    onClick={saveChanges}
                    icon="fas fa-times"
                    text="Sla wijzigingen op"
                  />

                  <Button
                    className={styles.btn}
                    onClick={goBack}
                    icon="fas fa-times"
                    text="Ga terug"
                  />
                </div>
              </div>
            </>
          )
        }
        {
          view === VIEWS.ORDER_INFO && (
            <>
              <div className="modal-header">
                <h2 className={`${styles.title}`}>
                  {selectedOrder?.EN_nr} - {selectedOrder?.date}
                </h2>

                <i className="fas fa-times" onClick={goBack} />
              </div>
              <div className="modal-content">

                <div className={styles.infoContainer}>
                  <div>
                    <strong>Naam:</strong> {selectedOrder?.name}
                  </div>
                  <div>
                    <strong>Adres:</strong> {selectedOrder?.address}
                  </div>

                  <div>
                    <strong>Email:</strong> {selectedOrder?.email}
                  </div>

                  <div>
                    <strong>Telefoon:</strong> {selectedOrder?.telno}
                  </div>

                  <div>
                    <strong>Aantal kersen:</strong> {selectedOrder?.amount}kg
                  </div>

                  <div>
                    <strong>Aantal boxen:</strong> {selectedOrder?.boxes}
                  </div>

                  <div>
                    <strong>Opmerking:</strong> {
                      selectedOrder?.message === ''
                        ? '/'
                        : selectedOrder?.message
                    }
                  </div>

                </div>

                {
                  ['unpaid', 'manual'].includes(selectedOrder?.state) && (
                    <div style={{ marginTop: '20px' }}>
                      <Button
                        onClick={cancelOrder}
                        className={styles.red}
                        icon="fas fa-times"
                        text="Bestelling annuleren"
                      />
                    </div>
                  )
                }

                <div className={styles.secondActions}>
                  <Button
                    className={styles.btn}
                    onClick={showEditInfo}
                    icon="fas fa-edit"
                    text="Wijzig gegevens"
                  />

                  <Button
                    className={styles.btn}
                    onClick={goBack}
                    icon="fas fa-times"
                    text="Ga terug"
                  />
                </div>
              </div>
            </>

          )
        }
        {
          view === VIEWS.WARNING && (
            <>
              <div className="modal-header">
                <h2 className={`${styles.title} ${styles.warningTitle}`}>
                  Opgepast!
                </h2>

                <i className="fas fa-times" onClick={goBack} />
              </div>
              <div className="modal-content">
                <div className={styles.warningMessage}>
                  Bent u zeker dat u de bestelling <strong>{selectedOrder?.EN_nr}</strong> van <strong>{selectedOrder?.name}</strong> wenst te markeren als niet afgehaald?
                </div>

                <div className={styles.mainActions}>
                  <Button
                    className={styles.markAsUnPaid}
                    onClick={markAsUnpaid}
                    icon="fas fa-check"
                    text="Ja, markeer als niet afgehaald"
                  />

                  <Button
                    className={styles.btn}
                    onClick={goBack}
                    icon="fas fa-times"
                    text="Nee, ga terug"
                  />
                </div>
              </div>
            </>
          )
        }

        {
          view === VIEWS.CONTROLS && selectedTab === TABS.CALCULATOR && (
            <>
              <div className="modal-header">
                <div className={styles.tabs}>
                  <div
                    className={
                      `${styles.tab} 
                     ${selectedTab === TABS.INFO ? styles.active : undefined}
                    `}

                    onClick={() => setSelectedTab(TABS.INFO)}
                  >
                    <i className="fas fa-info" />
                  </div>

                  <div className={
                    `${styles.tab} 
                     ${selectedTab === TABS.CALCULATOR ? styles.active : undefined}
                    `}

                    onClick={() => setSelectedTab(TABS.CALCULATOR)}
                  >
                    <i className="fas fa-calculator" />
                  </div>
                </div>

                <i className="fas fa-times" onClick={closeEditOrders} />
              </div>

              <div className="modal-content">
                <Calculator order={selectedOrder} setReset={setReset} />

                <div className={styles.mainActions}>
                  <Button
                    onClick={execReset}
                    className={`${styles.close} ${styles.green}`}
                    icon="fas fa-sync"
                    text="Reset de calculator"
                  />

                  <Button
                    onClick={() => setSelectedTab(TABS.INFO)}
                    className={styles.close}
                    icon="fas fa-times"
                    text="Sluit de calculator"
                  />
                </div>
              </div>
            </>


          )
        }


        {
          view === VIEWS.CONTROLS && selectedTab === TABS.INFO && (
            <>
              <div className="modal-header">
                <div className={styles.tabs}>
                  <div
                    className={
                      `${styles.tab} 
                     ${selectedTab === TABS.INFO ? styles.active : undefined}
                    `}

                    onClick={() => setSelectedTab(TABS.INFO)}
                  >
                    <i className="fas fa-info" />
                  </div>

                  <div className={
                    `${styles.tab} 
                     ${selectedTab === TABS.CALCULATOR ? styles.active : undefined}
                    `}

                    onClick={() => setSelectedTab(TABS.CALCULATOR)}
                  >
                    <i className="fas fa-calculator" />
                  </div>
                </div>

                <h2 className={styles.title}>
                  {selectedOrder?.EN_nr} - {selectedOrder?.date}
                </h2>

                <i className="fas fa-times" onClick={closeEditOrders} />
              </div>
              <div className="modal-content">
                <div className={styles.stateContainer}>
                  <div className={styles.name}>
                    {selectedOrder?.name}
                  </div>
                  <div
                    className={styles.state}
                    style={{
                      color: getColor(selectedOrder?.state, selectedOrder?.paid_with)
                    }}
                  >

                    {
                      selectedOrder?.state !== 'canceled' && (
                        <>
                          {
                            `${getTranslation(selectedOrder?.state, selectedOrder?.paid_with)}: €${selectedOrder?.price} `
                          }
                        </>
                      )
                    }

                    {
                      selectedOrder?.state === 'canceled' && (
                        <>
                          {
                            `${getTranslation(selectedOrder?.state, selectedOrder?.paid_with)}`
                          }
                        </>
                      )
                    }



                    {
                      selectedOrder?.state === 'paid' && selectedOrder?.paid_with !== 'mollie' && (
                        <>
                          betaald met {getPaidWithTranslation(selectedOrder?.paid_with)}
                        </>
                      )
                    }

                  </div>
                </div>

                {
                  selectedOrder?.state === 'canceled' && (
                    <div>
                      <div className={styles.mainActions}>
                        <Button
                          className={styles.markAsPaid}
                          onClick={markAsUnpaid}
                          icon="fas fa-undo"
                          text="Activeer bestelling"
                        />
                      </div>
                      <div className={styles.secondActions}>
                        <Button
                          onClick={showOrderInfo}
                          className={styles.viewInfo}
                          icon="fas fa-info"
                          text="Bekijk bestel-informatie"
                        />

                        <Button
                          onClick={closeEditOrders}
                          className={styles.close}
                          icon="fas fa-times"
                          text="Sluit de bestelling"
                        />
                      </div>
                    </div>
                  )
                }

                {
                  selectedOrder?.state !== 'canceled' && (
                    <>
                      <div className={styles.amountContainer}>
                        <div className={styles.cherryImgContainer}>
                          <img src={cherryIcon} className={styles.cherryImg} />
                          {selectedOrder?.amount}kg
                        </div>

                        <div>
                          <i className="fas fa-shopping-basket" />
                          {selectedOrder?.boxes}
                        </div>
                      </div>

                      {
                        selectedOrder?.message !== '' && (
                          <div className={styles.message}>
                            <strong>Opmerking: </strong>
                            {selectedOrder?.message}
                          </div>
                        )
                      }

                      <div className={styles.mainActions}>
                        {
                          selectedOrder?.paid_with === 'mollie' && (
                            <>
                              <Button
                                className={styles.green}
                                onClick={completeMollieOrder}
                                icon="fas fa-check"
                                text="Markeren als afgehaald"
                              />

                              <Button
                                className={`${styles.btn} ${styles.grey}`}
                                onClick={addOrder}
                                icon="fas fa-plus"
                                text="Voeg nieuwe bestelling toe"
                              />

                            </>
                          )
                        }

                        {
                          selectedOrder?.paid_with === 'mollie_finished' && (
                            <Button
                              className={styles.markAsUncompleted}
                              onClick={displayWarningForUnpaid}
                              icon="fas fa-undo"
                              text="Markeren als niet afgehaald"
                            />
                          )
                        }
                        {
                          selectedOrder?.state === 'paid'
                          && (selectedOrder?.paid_with !== 'mollie_finished' && selectedOrder?.paid_with !== 'mollie')
                          && (
                            <>
                              <Button
                                className={styles.markAsUnPaid}
                                onClick={displayWarningForUnpaid}
                                icon="fas fa-undo"
                                text="Markeren als onbetaald"
                              />

                              <Button
                                className={`${styles.btn} ${styles.grey}`}
                                onClick={addOrder}
                                icon="fas fa-plus"
                                text="Voeg nieuwe bestelling toe"
                              />
                            </>

                          )
                        }
                        {
                          (selectedOrder?.state === 'unpaid' || selectedOrder?.state === 'manual') && (
                            <>
                              <Button
                                onClick={markAsPaidWithCard}
                                icon="fas fa-credit-card"
                                className={styles.green}
                                text="Betaald met de kaart"
                              />

                              <Button
                                onClick={markAsPaidWithCash}
                                className={styles.green}
                                icon="fas fa-coins"
                                text="Betaald met cash"
                              />

                              <Button
                                className={`${styles.btn} ${styles.grey}`}
                                onClick={addOrder}
                                icon="fas fa-plus"
                                text="Voeg nieuwe bestelling toe"
                              />

                            </>
                          )
                        }

                        {
                          selectedOrder?.state === '' && (
                            <Button
                              onClick={markAsPaidWithCash}
                              className={styles.markAsCompleted}
                              icon="fas fa-check"
                              text="Markeren als afgehaald"
                            />
                          )
                        }
                      </div>

                      <div className={styles.secondActions}>
                        <Button
                          className={styles.viewInfo}
                          text="Bekijk bestel-informatie"
                          onClick={showOrderInfo}
                          icon="fas fa-info"
                        />

                        <Button
                          className={styles.close}
                          text="Sluit de bestelling"
                          onClick={closeEditOrders}
                          icon="fas fa-times"
                        />
                      </div>
                    </>
                  )
                }
              </div>
            </>

          )
        }
      </>
    )
  })
}

export default EditOrder
